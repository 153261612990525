 <template>
  <div>
    <CModal color="danger" :show.sync="deleteModal">
      <template v-slot:header>
        <h5 class="modal-title">Delete User</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(deleteModal)"
        >
          ×
        </button>
      </template>
      Do you want to delete "<b>{{ user ? user.first_name + " " + user.last_name : null}}</b>"?
      <template v-slot:footer>
        <CButton color="secondary" @click="closeModal(deleteModal)"
          >Cancel</CButton
        >

        <CButton color="secondary" class="px-4" disabled v-if="isSaving">
          <CSpinner grow size="sm" /> Deleting..
        </CButton>
        <CButton color="danger" @click="deleteUser(user)" v-else>
          Delete User
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "UserDelete",
  computed: {
    ...mapState({
      deleteModal: (state) => state.users.deleteModal,
      user: (state) => state.users.deleteUser,
      isSaving: (state) => state.users.isSaving,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("users/DELETE_MODAL", { bool: bool, user: null });
    },
    deleteUser(user) {
      this.$store.dispatch("users/destroyUser", {
        user: user
      });
    },
  },
};
</script>